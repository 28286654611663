import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "../../../../ui-treact/components/misc/Layouts.js"
import logo from "../../../../images/logoNav.png";
import FacebookIcon from "../../../../ui-treact/components/svgs/FacebookIcon";
import TwitterIcon from "../../../../ui-treact/components/svgs/TwitterIcon";
import YoutubeIcon from "../../../../ui-treact/components/svgs/YoutubeIcon";
import { Link } from "../../../utils/general.js";

// import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

// const StyledDiv = styled.div``

const Container = tw(ContainerBase)`bg-secondary-100 text-gray-100 w-full`
const Content = tw.div`max-w-screen-xl mx-auto py-8`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-20`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider text-secondary-900`;

const LinksContainer = tw.div`mt-8 font-semibold flex flex-wrap justify-center items-center flex-col sm:flex-row text-secondary-900 hover:no-underline hover:text-secondary-900 hocus:text-secondary-900`

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-secondary-900`
const links=[
  {
      heading:"Home",
      url:"/"
    },
  {
    heading:"About",
    url:"pages/about-us"
  },
  {
    heading:"Contact Us",
    url:"pages/contact-us"
  }
]
let linksData = links.map((link, i) => (
  <Link key={i} tw="mx-3 text-secondary-900 hover:no-underline hover:no-underline hover:text-secondary-900" to={link.url}>{link.heading}</Link>
  ))

export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>SICKMKTG</LogoText>
          </LogoContainer>
          <LinksContainer>
          {linksData}
            {/* <Link tw="mx-3 text-secondary-900 hover:no-underline hover:no-underline hover:text-secondary-900" to="/">Home</Link>
            <Link tw="mx-3 text-secondary-900 hover:no-underline hover:no-underline hover:text-secondary-900" to="pages/about">About</Link>
            <Link tw="mx-3 text-secondary-900 hover:no-underline hover:no-underline hover:text-secondary-900" to="#">Contact Us</Link> */}
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2021, SICKMKTG. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
